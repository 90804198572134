<template>
  <div class="elite-content" v-bind:class="[isActive ? 'unfold' : 'fold']">
    <notifications></notifications>
    <div
      class="elite-content_sidebar"
      :class="{ 'has-level2': $route.meta.hasLevel2 }"
      ref="sidebar"
    >
      <div
        class="elite-content_sidebar_level1"
        v-bind:class="[
          isActive ? 'unfold' : 'fold',
          shouldFold ? 'shouldFold' : ' ',
        ]"
      >
        <div class="elite-content_sidebar_level1_logo">
          <router-link :to="{ name: 'Dashboard' }">
            <span class="logo">
              <img src="/img/logo_reversed.svg" alt="logo" />
            </span>
            <span class="logo-icon">
              <img src="/img/SMAAART_Icon.svg" alt="logo-icon" />
            </span>
          </router-link>
          <!-- <button @click="toggleClass()">
            <i class="far fa-arrow-left"></i>
          </button> -->
        </div>
        <div class="elite-content_sidebar_level1_menu">
          <main-navbar-apps></main-navbar-apps>
        </div>
        <div class="elite-content_sidebar_level1_user">
          <div class="elite-content_sidebar_level1_user_avatar">
            <div class="elite-content_sidebar_level1_user_avatar_image">
              <base-dropdown
                v-if="me"
                title-classes="btn dropdown-button"
                menu-on-top
                :has-toggle="false"
                menu-classes="w-auto"
              >
                <template slot="title">
                  <div class="user-dropdown">
                    <span>
                      {{
                        `${me?.firstname?.charAt(0)}${me?.lastname?.charAt(0)}`
                      }}
                    </span>
                    <img
                      v-if="me?.profile_image"
                      :src="me?.profile_image"
                      alt="profile-picture"
                    />
                  </div>
                </template>
                <li>
                  <router-link to="/me/view" v-if="me">
                    <i class="fal fa-user"></i>
                    <span class="text-nowrap">
                      {{ `${me?.firstname + " " + me?.lastname}` }}
                    </span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/me/view?tab=locations" v-if="me">
                    <i class="ni ni-map-big"></i>
                    <span class="text-nowrap">
                      {{ $t("COMMON.MY_LOCATIONS") }}
                    </span>
                  </router-link>
                </li>
                <li
                  v-if="
                    !!me?.organization &&
                    $currentUserCan($permissions.PERM_VIEW_OWN_ORGANIZATIONS)
                  "
                >
                  <router-link :to="{ name: 'View Organization' }">
                    <i class="fas fa-cog"></i>
                    <span class="text-nowrap">
                      {{ $t("COMMON.MY_ORGANIZATION") }}
                    </span>
                  </router-link>
                </li>
                <li>
                  <button @click="logout">
                    <i class="fal fa-sign-out"></i
                    ><span class="text-nowrap">{{ $t("COMMON.LOGOUT") }}</span>
                  </button>
                </li>
              </base-dropdown>
            </div>
          </div>
          <div class="elite-content_sidebar_level1_user_lang">
            <div class="elite-content_sidebar_level1_user_lang_selected">
              <base-dropdown
                title-classes="btn btn-sm btn-neutral mr-0"
                menu-on-left
                id="lang-switcher"
                :has-toggle="false"
                menuClasses="lang-switcher"
              >
                <template slot="title">
                  <el-tooltip content="Langue" placement="bottom" effect="dark">
                    <img
                      ref="lang"
                      src="/img/locale_flags/canada-flag.svg"
                      :alt="$i18n.locale"
                      @click="getPosition"
                    />
                  </el-tooltip>
                </template>
                <li v-for="lang in langs" :key="lang.id">
                  <a
                    class="dropdown-item"
                    v-bind:class="{ active: lang.slug === $i18n.locale }"
                    @click="selectLang(lang.slug)"
                  >
                    <!--<img :src="`/img/locale_flags/${lang}.svg`" :alt="lang" />-->
                    <span class="text">{{ lang.text }}</span>
                    <span class="separtor"> - </span>
                    <span class="symbol">{{ lang.slug }}</span>
                    <i class="fal fa-check"></i>
                  </a>
                </li>
              </base-dropdown>
            </div>
          </div>
          <div class="elite-content_sidebar_level1_user_menu">
            <ul>
              <li class="notifications">
                <el-tooltip
                  content="Notifications"
                  placement="bottom"
                  effect="dark"
                >
                  <router-link to="/notifications" v-if="me">
                    <span class="notifications_wrapper">
                      <notifications-count />
                    </span>
                    <i class="far fa-bell"></i>
                  </router-link>
                </el-tooltip>
              </li>
              <li>
                <el-tooltip content="Messages" placement="bottom" effect="dark">
                  <a href="#"><i class="far fa-envelope"></i></a>
                </el-tooltip>
              </li>
              <li>
                <el-tooltip
                  content="Informations"
                  placement="bottom"
                  effect="dark"
                >
                  <a href="#"><i class="far fa-question-circle"></i></a>
                </el-tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <router-view name="SidebarSecondLevelMenu"></router-view>
    </div>
    <div
      class="elite-content_body"
      :style="{ width: `calc( 100% - ${width}px)`, marginLeft: width + 'px' }"
    >
      <button class="close-button" @click="toggleClass()">
        <i v-if="isActive === false" class="far fa-chevron-left"></i>
        <i v-else class="far fa-chevron-right"></i>
      </button>
      <div class="elite-content_body_inner">
        <div class="dealer-selection">
          <div class="dealer-selection-left"></div>
          <div class="dealer-selection-right">
            <div class="select-wrapper">
              <i class="fal fa-map-marker-alt"></i>
              <location-selector
                :allow-none="!me?.restrict_to_locations"
                :default-first-option="!!me?.restrict_to_locations"
                :disabled="isRestrictToOneLocation && !!globalLocation?.id"
                :filterOrganization="globalOrganization?.id"
                :location="globalLocation?.id"
                :options-value="currentUserRestrictLocations"
                :placeholder="$t('VEHICLES.SELECT_DEALER')"
                @locationChanged="setGlobalLocation"
                class="dealer-select"
                popper-class="custom-dealer-select"
                filterable
                show-label
              />
            </div>
            <!-- <el-button>
              <i class="far fa-plus-circle"></i>
              <span class="text">{{ $t("VEHICLES.QUICK_REQUEST") }}</span>
            </el-button> -->
          </div>
        </div>
        <router-view></router-view>
      </div>
    </div>
    <!--<div class="elite-content_bot">
      <button>
        <i class="fal fa-comments"></i>
      </button>
    </div>-->
  </div>
</template>

<script>
import { Button, Select, Option } from "element-ui";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import MainNavbarApps from "./partials/MainNavbarApps.vue";
import NotificationsCount from "./partials/NotificationsCount.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import swal from "sweetalert2";
import locationMixin from "../../../mixins/location-mixin";

export default {
  components: {
    MainNavbarApps,
    NotificationsCount,
    LocationSelector,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [locationMixin],

  data() {
    return {
      isActive: false,
      langs: [
        {
          id: 1,
          text: "Français",
          slug: "fr",
        },
        {
          id: 2,
          text: "Anglais",
          slug: "en",
        },
      ],
      width: null,
      observer: null,
      positionTop: 0,
      positionLeft: 0,
      shouldFold: false,
      options: [
        {
          value: "Albi Mazda Blainville",
          label: "Albi Mazda Blainville",
        },
        {
          value: "Albi Mazda Mascouche",
          label: "Albi Mazda Mascouche",
        },
        {
          value: "Albi Kia Mascouche",
          label: "Albi Kia Mascouche",
        },
      ],
      value: null,
    };
  },

  methods: {
    async setGlobalLocation(locationId, location) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      await this.$store.dispatch("locations/setGlobalLocation", location);
      const currentRoute = this.$router.currentRoute;

      try {
        await this.$router.push("/");
      } catch (e) {
        // console.log(e);
      }
      try {
        await this.$router.push(currentRoute);
      } catch (e) {
        // console.log(e);
      }
      swal.close();
    },

    toggleClass(event) {
      if (this.isActive) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },

    selectLang(lang) {
      loadLanguageAsync(lang);
    },

    sidebarObserver() {
      const box = this.$refs.sidebar,
        vm = this,
        config = {
          attributes: true,
          childList: true,
        };
      const elementToObserve = document.querySelector(".elite-content_sidebar");
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          vm.width = entry.contentRect.width;
        });
      });
      observer.observe(elementToObserve, config);
      this.observer = observer;
    },

    getPosition() {
      this.positionTop = this.$refs["lang"].getBoundingClientRect().top;
      this.positionLeft = this.$refs["lang"].getBoundingClientRect().left;
      const dropdown = document.querySelector(".lang-switcher");
      langButton.addEventListener("click", () => {
        if (this.isActive === false) {
          dropdown.style.cssText = `
          top: ${this.positionTop - 40}px;
          left: ${this.positionLeft}px;
        `;
        } else {
          dropdown.style.cssText = `bottom: 0; left: 0;`;
        }
      });
    },

    menuLinkClickHandler() {
      this.shouldFold = true;
      setTimeout(
        function () {
          this.shouldFold = false;
        }.bind(this),
        1000
      );
    },

    menuHoverObserver() {
      const firstMenuLinks = document.querySelectorAll(
        ".elite-content_sidebar_level1_menu_wrapper > ul > li > a"
      );
      for (const menuLink of firstMenuLinks) {
        menuLink.removeEventListener("click", this.menuLinkClickHandler);
      }
      for (const menuLink of firstMenuLinks) {
        menuLink.addEventListener("click", this.menuLinkClickHandler);
      }
    },

    selectAll() {
      this.value = this.options.map((item) => item.value);
    },

    logout() {
      try {
        this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },

  async mounted() {
    this.sidebarObserver();
  },

  computed: {},

  watch: {
    me: {
      handler: debounce(function (val) {
        this.menuHoverObserver();
      }),
      deep: true,
    },
  },
};
</script>
